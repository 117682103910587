// function RabbitMQ( onconnect_callback, onconnectfail_callback){
//   var ws = new SockJS('http://106.186.25.19:15674/stomp');
//   var client = Stomp.over(ws);

//   // SockJS does not support heart-beat: disable heart-beats
//   client.heartbeat.outgoing = 0;
//   client.heartbeat.incoming = 0;

//   var on_connect = function(x) {
//       onconnect_callback();
//   };
  
//   var on_error = function() {
//       onconnectfail_callback();
//       console.log("^^^^^^^^^^^^^^^^^^^^^^^");
//   };

//   var subscribe = function(exchange, queue,onmessagereceived_callback){
//     id = client.subscribe('/exchange/'+exchange+'/'+queue, function(d) {
//         onmessagereceived_callback(d.body);
//     });
//   };

//   var subscribe = function(queue, onmessagereceived_callback){
//     id = client.subscribe(queue, function(d) {
//         onmessagereceived_callback(d.body);
//     });
//   };  

//   var subscribe;



//   client.connect('kumar', 'kumar', on_connect, on_error, 'connectkumar');  
// }

// function RabbitMQ (queue, successcallback, failcallback){
//     var ws = new SockJS('http://106.186.25.19:15674/stomp');
//     var client = Stomp.over(ws);
//     client.heartbeat.outgoing = 0;
//     client.heartbeat.incoming = 0;
//     var connect = function(){
//       console.log("Connecting 1");
//     };

// //     connect_queue : function(queue, errorcallback){
// //       console.log("This connect");
// //       this.ws = new SockJS('http://106.186.25.19:15674/stomp');
// //       this.client = Stomp.over(this.ws);
// //       this.client.heartbeat.outgoing = 0;
// //       this.client.heartbeat.incoming = 0;
// //       this.client.connect('kumar', 'kumar', subscribe_queue(queue), errorcallback, 'connectkumar');  
// //     },

// //     connect_queue_exchange : function(exchange, queue, errorcallback){
// //       console.log("This connect2");
// //       this.ws = new SockJS('http://106.186.25.19:15674/stomp');
// //       this.client = Stomp.over(this.ws);
// //       this.client.heartbeat.outgoing = 0;
// //       this.client.heartbeat.incoming = 0;
// //       this.client.connect('kumar', 'kumar', this.subscribe_queue_exchange(exchange,queue), errorcallback, 'connectkumar');  
// //     },

// //     subscribe_queue_exchange : function(exchange, queue,onmessagereceived_callback){
// //         console.log("Subscribing with exchange");
// //     },

// //     subscribe_queue : function(queue, onmessagereceived_callback){
// //       console.log("Subscribing without exchange");
// // //         id = this.client.subscribe(queue, function(d) {
// // // //         onmessagereceived_callback(d.body);
// // //       });
// //     }
//       client.connect('kumar', 'kumar', connect, failcallback, 'connectkumar');  
// }



// function RabbitMQOld (successcallback, failcallback, queue, exchange){
//     var ws = new SockJS('http://106.186.25.19:15674/stomp');
//     var client = Stomp.over(ws);
//     client.heartbeat.outgoing = 0;
//     client.heartbeat.incoming = 0;

//     var connect = function(){
//     }

//     var subscribe_queue_exchange = function(){
//       id = client.subscribe('/exchange/'+exchange+'/'+queue, function(d) {
//           successcallback(d.body);
//       });        
//     }

//     var subscribe_queue = function(){
//       id = client.subscribe(queue, function(d) {
//           successcallback(d.body);
//       });
//     }

//     if(exchange){
//       client.connect('kumar', 'kumar', subscribe_queue_exchange, failcallback, 'connectkumar');         
//     }else{
//       client.connect('kumar', 'kumar', subscribe_queue, failcallback, 'connectkumar');         
//     }
    
// }

function RabbitMQ (successcallback, failcallback, queue, exchange){
  var ws = new WebSocket('wss://rmq28881.cashkumar.com:15671/ws');
  var client = Stomp.over(ws);
  // client.heartbeat.outgoing = 0;
  // client.heartbeat.incoming = 0;
  client.debug = 0;

    // console.log(client);

    var connect = function(){
    }

    var subscribe_queue_exchange = function(){
      id = client.subscribe('/exchange/'+exchange+'/'+queue, function(d) {
          successcallback(d.body);
      });        
    }

    var subscribe_queue = function(){
      id = client.subscribe(queue, function(d) {
          successcallback(d.body);
      });
    }

    if(exchange){
      client.connect('kumar', 's3crEt', subscribe_queue_exchange, failcallback, 'connectkumar');         
    }else{
      client.connect('kumar', 's3crEt', subscribe_queue, failcallback, 'connectkumar');         
    }
    
}