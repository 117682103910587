$(function(){
    /*
        Allows you to add data-method="METHOD to links to automatically inject a form with the method on click
        Example: <a href="{{route('customers.destroy', $customer->id)}}" data-method="delete" name="delete_item">Delete</a>
        Injects a form with that's fired on click of the link with a DELETE request.
        Good because you don't have to dirty your HTML with delete forms everywhere.
     */
    $('[data-method]').append(function(){
        return "\n"+
        "<form action='"+$(this).attr('href')+"' method='POST' name='delete_item' style='display:none'>\n"+
        "   <input type='hidden' name='_method' value='"+$(this).attr('data-method')+"'>\n"+
        "   <input type='hidden' name='_token' value='"+$('meta[name="_token"]').attr('content')+"'>\n"+
        "</form>\n"
    })
        .removeAttr('href')
        .attr('style','cursor:pointer;')
        .attr('onclick','$(this).find("form").submit();');

    /*
        Generic are you sure dialog
     */
    $('form[name=delete_item]').submit(function(){
        return confirm("Are you sure you want to delete this item?");
    });

    /*
        Bind all bootstrap tooltips
     */
    $("[data-toggle=\"tooltip\"]").tooltip();

    $('.numbersOnly').keyup(function () { 
        this.value = this.value.replace(/[^0-9\.]/g,'');
    });

    $('[data-toggle="validator"]').validator({
      custom: {
        expcheck: function ($el) {
          var elid = $el.data('expcheck');
            var curexp = parseInt($(elid).val());
            var totexp = parseInt($el.val());
            return totexp >= curexp;
        }
      },
      errors: {
        expcheck: 'Total experience cannot be less than current'
      }
    });

    function dropMobileLeadBtnClicked(mobilelead, dropcommentsid) {
        alert(mobilelead);
    }

});